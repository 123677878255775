<template>
  <v-container fluid>
    <PageHeaderSlot :showBackButton="$route.name !== 'StudentUserAttendance'"> </PageHeaderSlot>

    <v-card class="mb-6">
      <v-card-text class="px-4">
        <h6 class="text-h6 colorBlack--text mb-6">{{ $t('packageRecord') }}</h6>
        <v-row>
          <v-col cols="12" sm="4" lg="3">
            <div class="package-item">
              <span>{{ $t('canUseLessonCount') }} : </span>
              <span>{{ packageLessonCanUse }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <div class="package-item">
              <span>{{ $t('unassignLesson') }} : </span>
              <span>{{ packageLessonAvailable }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <div class="package-item">
              <span>{{ $t('toBeAttended') }} : </span>
              <span>{{ packagePendingAttend }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" lg="3">
            <div class="package-item">
              <span>{{ $t('usedLessonCountAttend') }} : </span>
              <span>{{ packageLessonUsedAttend }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <div class="package-item">
              <span>{{ $t('usedLessonCountAbsent') }} : </span>
              <span>{{ packageLessonUsedAbsent }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <div class="package-item">
              <span>{{ $t('totalPackageLessonCount') }} : </span>
              <span>{{ packageTotalLesson }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-progress-linear indeterminate color="primary darken-2" v-if="summaryLoading"></v-progress-linear>
    <v-toolbar flat class="custom-table-toolbar mb-6 no-wrap attendance-toolbar" height="auto">
      <v-toolbar-items class="toolbar-left-items flex-grow-1 justify-space-between">
        <FormDatePicker
          :dateValue.sync="selectedYearMonth"
          dense
          hideDetails
          customInputClass="mr-3 mt-2 mb-3 search-input-field"
          :disabled="summaryLoading"
          @changed="searchWithYearMonth()"
          isYearMonthOnly
          disableClear
        />

        <div class="attendance-toolbar__item">{{ $t('totalLessonCount') }} : {{ totalLessons }}</div>
        <div class="attendance-toolbar__item">{{ $t('attend') }} : {{ attends }}</div>
        <div class="attendance-toolbar__item">{{ $t('absent') }} : {{ absents }}</div>
        <div class="attendance-toolbar__item">{{ $t('upcoming') }} : {{ pending }}</div>
      </v-toolbar-items>
    </v-toolbar>

    <Datatable
      :isLoading="tableLoading"
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page="tablePage"
      :pageLimit="tableLimit"
      :itemTotal="tableItemTotal"
      enableClearAll
      @all-search-cleared="clearSearch()"
      @refresh-clicked="refreshData()"
      enableRefresh
      @options-update="onTableOptionsChange($event)"
      enableSearchDate
      datePlaceholder="classDate"
      @date-selected="searchAttendDate($event)"
    >
      <template v-slot:[`item.attendance`]="{ item }">
        <v-chip
          :class="{
            'chip-active': item.attendance === 'attend',
            'chip-inactive': item.attendance === 'absent',
          }"
        >{{ item.attendance | formatAttendanceStatus }}</v-chip>
      </template>
    </Datatable>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'

export default {
  name: 'StudentAttendance',
  components: {
    Datatable,
    FormDatePicker,
  },
  data: (vm) => ({
    id: null,
    tableHeaders: [
      { value: 'date', text: vm.$t('classDate') },
      { value: 'timeslot', text: vm.$t('time') },
      { value: 'course_code', text: vm.$t('courseCode') },
      { value: 'course_name', text: vm.$t('courseName') },
      { value: 'attendance', text: vm.$t('attendStatus'), align: 'center' },
    ],

    tableLoading: false,
    tableData: [],
    tablePage: 1,
    tableLimit: 10,
    tableItemTotal: 0,
    tableSearchOrderDate: '',

    selectedYearMonth: '',
    totalLessons: 0,
    attends: 0,
    absents: 0,
    pending: 0,

    packageLessonCanUse: 0,
    packageLessonAvailable: 0,
    packagePendingAttend: 0,
    packageLessonUsedAttend: 0,
    packageLessonUsedAbsent: 0,
    packageTotalLesson: 0,

    summaryLoading: true
  }),
  methods: {
    async getStudentPackageInfo() {
      try {
        const data = await this.$Fetcher.GetStudentPackageInfo(this.id)
        this.packageTotalLesson = data.total; // 已購買總堂數
        this.packageLessonUsedAttend = data.attendance; // 已使用堂數 (出席)
        this.packageLessonUsedAbsent = data.absent; // 已使用堂數 (缺席)
        this.packagePendingAttend = data.pending; // 待出席
        this.packageLessonAvailable = data.total - data.attendance - data.absent - data.pending - data.debit; // 未指派課堂
        if (this.packageLessonAvailable < 0) {
          this.packageLessonAvailable = 0;
        }

        this.packageLessonCanUse = this.packageLessonAvailable + this.packagePendingAttend; // 可用堂數 = 未指派課堂 + 待出席
        
      } catch (err) {
        this.$common.error(err)
      } finally {
        await this.getAttendanceData();
        this.getMonthAttendanceSummary();
      }
    },

    async getAttendanceData() {
      this.tableLoading = true
      try {
        let payload = {
          filter_limit: this.tableLimit,
          filter_page: this.tablePage - 1,
          filter_item: [],
        }

        if (this.$validate.DataValid(this.tableSearchOrderDate)) {
          this.selectedYearMonth = this.tableSearchOrderDate.substring(0, 7)
          payload.filter_item.push({ key: 'date', value: this.tableSearchOrderDate })
        }

        const { data, total } = await this.$Fetcher.GetStudentAttendances(this.id, payload)
        this.tableItemTotal = total
        this.tableData = data.map(el => {
          return {
            id: el.lesson_id,
            date: el.date,
            start_time: el.start_time,
            end_time: el.end_time,
            course_code: el.course_code,
            course_name: el.course_name,
            attendance: el.attend_status,
          }
        })

        this.tableLoading = false
      } catch (err) {
        this.$common.error(err)
        this.tableData = []
        this.tableLoading = false
      }
    },

    async getMonthAttendanceSummary() {
      this.summaryLoading = true;
      try {
        let payload = {
          filter_item: [],
        }

        if (!this.selectedYearMonth) {
          this.selectedYearMonth = today.substring(0, 7)
        }
        const year = parseInt(this.selectedYearMonth.substring(0, 4))
        const month = parseInt(this.selectedYearMonth.substring(5, 7))
        const startDate = new Date(year, month - 1, 1)
        payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(startDate) })
        const lastDateOfMonth = new Date(year, month, 0)
        payload.filter_item.push({ key: 'end_date', value: this.$formatter.formatDate(lastDateOfMonth) })

        const { total, attend, absent, pending } = await this.$Fetcher.GetStudentAttendances(this.id, payload)
        this.totalLessons = total
        this.attends = attend
        this.absents = absent
        this.pending = pending
      } catch (err) {
        this.$common.error(err)
        this.totalLessons = 0
        this.attends = 0
        this.absents = 0
        this.pending = 0
      } finally {
        this.summaryLoading = false;
      }
    },

    searchAttendDate(date) {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchOrderDate = date
      this.getAttendanceData()
    },
    clearSearch() {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchOrderDate = ''
      this.getAttendanceData()
    },
    refreshData() {
      this.tableLimit = 10
      this.tablePage = 1
      this.tableItemTotal = 0
      this.getAttendanceData()
    },
    onTableOptionsChange(options) {
      if (options.itemsPerPage !== this.tableLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      this.tableLimit = options.itemsPerPage
      this.getAttendanceData()
    },
    searchWithYearMonth() {
      this.getMonthAttendanceSummary()
    },

    async handleSiteLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: StudentAttendance')
      if (this.$validate.DataValid(this.id)) {
        await this.getStudentPackageInfo()
      }
    },
  },
  destroyed() {
    window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  mounted() {
    this.$store.dispatch('toggleLoadingPage', true)
    const id = parseInt(this.$route.params.id)
    if (!isNaN(id)) {
      this.id = id;
    }

    const userType = this.getUserType();
    if (userType === 'student') {
      const userData = this.getCurrentUserData();
      this.id = userData.id;
      this.$store.dispatch('toggleLoadingPage', false)
    } else {
      if (this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    }
   
    this.tableLoading = true
    window.addEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  created() {
    const today = this.$formatter.formatDate(new Date())
    this.selectedYearMonth = today.substring(0, 7)
  },
}
</script>

<style lang="scss">
.attendance-toolbar {
  & .toolbar-left-items {
    width: 100%;
    max-width: 100% !important;
    align-items: center;
  }
}

.attendance-toolbar__item {
  padding: 1em;
  color: #505050;
  font-size: 0.875rem;
  font-weight: 500;
}

.package-item {
  display: flex;
  justify-content: space-between;
  max-width: 200px;

  & * {
    font-size: 0.875rem;
    color: #505050;
    font-weight: 500;
  }
}

@media (max-width: 992px) {
  .attendance-toolbar .custom-input {
    width: 100% !important;
    max-width: unset !important;
  }

  .attendance-toolbar__item {
    width: 25%;
  }
}

@media (max-width: 550px) {
  .attendance-toolbar__item {
    width: 50%;
  }
}
</style>



<!-- TODO: delete -->
<!-- async getAttendanceData() {
  this.tableLoading = true
  try {
    let payload = {
      filter_limit: this.tableLimit,
      filter_page: this.tablePage - 1,
      filter_item: [],
    }

    if (this.$validate.DataValid(this.tableSearchOrderDate)) {
      this.selectedYearMonth = this.tableSearchOrderDate.substring(0, 7)
      payload.filter_item.push({ key: 'date', value: this.tableSearchOrderDate })
    } else {
      if (!this.selectedYearMonth) {
        this.selectedYearMonth = today.substring(0, 7)
      }

      const year = parseInt(this.selectedYearMonth.substring(0, 4))
      const month = parseInt(this.selectedYearMonth.substring(5, 7))
      const startDate = new Date(year, month - 1, 1)
      payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(startDate) })
      const lastDateOfMonth = new Date(year, month, 0)
      payload.filter_item.push({ key: 'end_date', value: this.$formatter.formatDate(lastDateOfMonth) })
    }

    const { data, total, attend, absent, pending } = await this.$Fetcher.GetStudentAttendances(this.id, payload)
    this.tableItemTotal = total
    this.tableData = data.map(el => {
      return {
        id: el.lesson_id,
        date: el.date,
        start_time: el.start_time,
        end_time: el.end_time,
        course_code: el.course_code,
        course_name: el.course_name,
        attendance: el.attend_status,
      }
    })

    if (this.tablePage <= 1) {
      this.totalLessons = total
      this.attends = attend
      this.absents = absent
      this.pending = pending
    }

    this.tableLoading = false
  } catch (err) {
    this.$common.error(err)
    this.tableData = []
    this.tableLoading = false
    this.totalLessons = 0
    this.attends = 0
    this.absents = 0
    this.pending = 0
  }
}, -->